@font-face {
	font-family: 'GothamBlack';
	src: local('GothamBook'), url('./assets/fonts/Gotham-Black.otf') format('opentype');
}

@font-face {
	font-family: 'NunitoRegular';
	src: local('NunitoRegular'), url('./assets/fonts/NunitoSans-Regular.ttf') format('truetype');
}

* {
   margin: 0;
  padding: 0;
  font-family: 'NunitoRegular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, * {
  font-family: 'NunitoRegular' !important;
}

a {
  text-decoration: none !important;
  color: #339dff !important;
  transition: all 0.25s ease-in-out;
}

a:hover {
  color: #ffb108 !important;
}

.pointer {
  cursor: pointer;
}

:root {
  --animate-duration: 300ms;
}